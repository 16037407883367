<template>
  <div class="footer">
    <div class="lang">
      <b id="paginaCurrent">{{ numberPage }}</b> | {{ totalNumber }}
    </div>
  </div>
</template>

<script>

export default {
  name: "exported-footer",
  props: ["numberPage", "totalNumber"],
  data() {
    return {
    };
  },
};
</script>

<style scoped>


@font-face {
  font-family: Montserrat Thi;
  src: url("../assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("../assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("../assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("../assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

.lang {
  font-family: Montserrat Regular, sans-serif;
  font-size: 13px;
  position: fixed;
  bottom: 20px;
  height: 29px;
  width: 97.6%;
  margin-left: -15px;
}

.footer {
  width: 100%;
  position: fixed;
  margin-left: 760px;
  margin-right: 20px;
}
.footer__buttons {
  display: flex;
  justify-content: center;
  width: 200px;
  border: 1px solid purple;
  margin: 10px auto;
}
.footer__buttons--center {
  padding: 0px 20px;
}
</style>