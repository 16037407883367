import {
    get,
}from '../utils/http';

export function getProjects(){
    return get('/projects/external/');
}

export function getProjectsNames(){
    return get('/projects/external/names');
}

export function getProjectByType(projectType){
    return get('/projects/external/type?value=' + projectType);
}

export function getProjectById(projectId){
    return get('/projects/external/:' + projectId);
}

export function getProjectByName(name){
    return get('/projects/external/' + name);
}