<template>
  <div class="profile">
    <div class="row">
      <img class="member-photo" :src="'data:image/jpeg;base64,' + memberPhoto" />
      <div style="display: block">
      <span class="team-member">{{ memberName }}</span>
      <span class="team-role"> {{ memberFunction }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile",
  props: ["memberName", "memberFunction", "memberPhoto"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-flow: row;
  position: relative;
}
.member-photo {
  width: 70px;
  border-radius: 50%;
  margin-left: 50px;
}

.team-member {
  font-size: 10px;
  font-family: Montserrat;
  padding-left: 15px;
  color: #0b71b3;
  text-transform: uppercase;
  font-weight: bolder;
  float: left;
  clear: left;
}

.team-role {
  font-size: 10px;
  font-family: Montserrat;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  float: left;
  clear: left;
  padding-top: 5px;
  padding-left: 15px;
}

</style>