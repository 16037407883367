<template>
  <div class="team">
    <div class="team-template">
      <Header style="margin-top: 0px; color:black" />

      <h2 class="vss" style="padding-top: 50px">EQUIPA</h2>
      <div class="line"></div>

      <h4 class="team-text" id="team" style="margin-bottom: 40px">
        {{ teamIntro }}
      </h4>
      <span class="roles" v-if="this.managers.length > 0">Gestor</span>
      <ul class="flex-container">
        <li class="flex-item" :key="manager.name" v-for="manager in managers">
          <Profile
            :member-name="manager.name"
            :member-function="manager.function"
            :member-photo="manager.image"
          />
        </li>
      </ul>

      <span class="roles" v-if="this.developers.length > 0">Programadores</span>
      <span class="roles in-english" v-if="this.developers.length > 0">/ Developers</span>
      <ul class="flex-container">
        <li
          class="flex-item"
          :key="developer.name"
          v-for="developer in developers"
        >
          <Profile
            :member-name="developer.name"
            :member-function="developer.function"
            :member-photo="developer.image"
          />
        </li>
      </ul>

      <span class="roles" v-if="this.designers.length > 0">Design & Multimedia</span>
      <ul class="flex-container">
        <li
          class="flex-item"
          :key="designer.name"
          v-for="designer in designers"
        >
          <Profile
            :member-name="designer.name"
            :member-function="designer.function"
            :member-photo="designer.image"
          />
        </li>
      </ul>
      <span class="roles" v-if="this.dataScientists.length > 0">Data Scientists</span>
      <ul class="flex-container">
        <li
          class="flex-item"
          :key="dataScientist.name"
          v-for="dataScientist in dataScientists"
        >
          <Profile
            :member-name="dataScientist.name"
            :member-function="dataScientist.function"
            :member-photo="dataScientist.image"
          />
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
import Header from "../components/Header.vue";
import Profile from "../components/Profile.vue";
import { getCompanyTeam } from "../api/companies";

export default {
  name: "team",
  props: ["pageNumber"],
  data() {
    return {
      teamIntro: null,
      managers: [],
      developers: [],
      designers: [],
      dataScientists: [],
    };
  },
  components: {
    Header,
    Profile,
  },
  methods: {},
  mounted() {
    this.$root.$on("changepage", (currentPage) => {

      if (this.teamIntro == null) {
        if (this.pageNumber == currentPage) {
          getCompanyTeam()
            .then((response) => {
              this.teamIntro = response.data.team.intro.pt;

              for (var i in response.data.team.members) {
                if (response.data.team.members[i].type === "GESTOR") {
                  this.managers.push({
                    name: response.data.team.members[i].name,
                    function: response.data.team.members[i].function,
                    image: response.data.team.members[i].images
                  });
                } else if (
                  response.data.team.members[i].type === "PROGRAMADOR"
                ) {
                  this.developers.push({
                    name: response.data.team.members[i].name,
                    function: response.data.team.members[i].function,
                    image: response.data.team.members[i].images
                  });
                } else if (
                  response.data.team.members[i].type === "DESIGN E MULTIMEDIA"
                ) {
                  this.designers.push({
                    name: response.data.team.members[i].name,
                    function: response.data.team.members[i].function,
                    image: response.data.team.members[i].images
                  });
                } else {
                  this.dataScientists.push({
                    name: response.data.team.members[i].name,
                    function: response.data.team.members[i].function,
                    image: response.data.team.members[i].images
                  });
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
  },
};
</script>

<style>
.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  padding: 0;
  margin: 0;
  list-style: none;
}

.flex-item {
  margin: 20px 30px;
  padding: 5px;
  text-align: left;
}

.team-template {
  height: 1042px;
  width: 795px;
  overflow: hidden;
  display: block;
}

.roles {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  text-align: left;
  margin-left: 90px;
  line-height: 1.7;
  color: black;
  font-weight: bolder;
}

.in-english {
  color: #bcbcbc;
  display: inline-block;
  margin-left: 5px;
}
.team-text {
  font-family: Montserrat Thi, sans-serif;
  font-size: 14px;
  text-align: left;
  padding-left: 80px;
  padding-top: 15px;
  padding-right: 80px;
  line-height: 1.7;
  color: black;
}

.line {
  position: absolute;
  height: 0.2px;
  width: 220px;
  background-color: rgb(223, 223, 223);
  margin-top: 0px;
  margin-left: 80px;
}

@font-face {
  font-family: Montserrat Thi;
  src: url("../assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("../assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("../assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("../assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

@font-face {
  font-family: Montserrat Blac;
  src: url("../assets/Montserrat/Montserrat-Black.ttf");
  font-weight: black;
}

.team {
  background-color: #f9f7f7;
  background-size: 230%;
  background-position: 10% 5%;
  background-size: 230%;
  height: 1042px;
  width: 795px;
  justify-content: center;
  align-content: center;
  margin-right: auto;
  margin-left: auto;
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  /*overflow-y:auto;
  overflow-x: auto;*/
}

h3 {
  font-family: Montserrat Thi, sans-serif;
  font-size: 16px;
  text-align: left;
  padding-left: 80px;
  padding-top: 70px;
  padding-right: 300px;
  line-height: 2.3;
}

.vss {
  font-family: Montserrat Blac, sans-serif;
  font-size: 19px;
  text-align: left;
  padding-left: 80px;
  padding-top: 100px;
  padding-right: 300px;
  color: #194980ce;
}
</style>