import axios from 'axios';
export function post(uri, data) {
    return axios.post(endpoint(uri), data)
}

export function formData(uri, data) {
    let config = {}
    config.headers['Content-Type'] = 'multipart/form-data'
    return axios.post(endpoint(uri), data, config)
}

export function formDataPut(uri, data) {
    let config = {}
    config.headers['Content-Type'] = 'multipart/form-data'
    return axios.put(endpoint(uri), data, config)

}

export function put(uri, data) {
    return axios.put(endpoint(uri), data)
}

export function del(uri, data) {
    return axios.delete(endpoint(uri), {
        data
    })
}

export function get(uri) {
    return axios.get(endpoint(uri))
}

export function endpoint(uri) {
    return process.env.VUE_APP_API_URL + uri
}