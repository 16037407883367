import {
    get,
}from '../utils/http';

export function getIoSolutions(){
    return get('/projects/internal/');
}

export function getIoSolutionsNames(){
    return get('/projects/internal/names');
}

export function getIoSolutionById(iosolutionId){
    return get('/projects/internal/:' + iosolutionId);
}

export function getIoSolutionByName(name){
    return get('/projects/internal/' + name);
}