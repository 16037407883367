<template>
  <div class="numbers">
    <Header style="margin-top: 0px; color:black" />

    <h2 class="team-numbers">Números</h2>
    <div class="line"></div>

    <span class="numbers-text">{{ this.description }}</span>

    <div class="h-resources round">
      <span class="n">{{ this.resources }}</span>
    </div>
    <span class="hr format">Recursos Humanos com <br />contrato</span>
    <div class="tech-lang round">
      <span class="n">{{ this.technologies.length }}</span>
    </div>
    <span class="tch format"
      >Tecnologias / Linguagens
      <p style="font-family: Montserrat Regular; margin-top: 5px">
        <span :key="technology" v-for="technology in technologies">{{
          technology
        }}</span>
      </p></span
    >
    <div class="shareholders round">
      <span class="n">{{ this.shareholders.length }}</span>
    </div>
    <span class="ac format"
      >Acionistas
      <p style="font-family: Montserrat Regular; margin-top: 5px">
        <span :key="shareholder.name" v-for="shareholder in shareholders">{{
          shareholder.percentage + " " + shareholder.name
        }}</span>
      </p></span
    >
    <div class="students round">
      <span class="n">{{ this.students }}</span>
    </div>
    <span class="st format"
      >Estudantes
      <p style="font-family: Montserrat Regular; margin-top: 5px">
        <span :key="institution" v-for="institution in institutions">{{
          institution
        }}</span>
      </p></span
    >
    <div class="io-solutions round">
      <span class="n">{{ ioSolutions }}</span>
    </div>
    <span class="ioS format"
      >ioSolutions
      <p style="font-family: Montserrat Regular; margin-top: 5px">
        No mercado: <br />ioHub | ioServ | ioAttend | ioGuest | ioCovid19
        <br />Em desenvolvimento: <br />ioEduc | ioVoucher
      </p></span
    >
    <div class="io-helpers round">
      <span class="n">{{ this.ioHelpers }}</span>
    </div>
    <span class="ioH format">ioHelpers</span>
    <div class="io-agents round">
      <span class="n">{{ this.ioAgents }}</span>
    </div>
    <span class="ioA format">ioAgents</span>
  </div>
</template>


<script>
import Header from "../components/Header.vue";
import { getCompanyNumbers } from "../api/companies";
import { getIoSolutions } from "../api/iosolutions";

export default {
  name: "numbers",
  props: ["pageNumber"],
  data() {
    return {
      description: null,
      students: "",
      institutions: [],
      resources: "",
      technologies: [],
      ioHelpers: "",
      ioAgents: "",
      shareholders: [],
      ioSolutions: "",
    };
  },
  components: {
    Header,
  },
  mounted() {
    this.$root.$on("changepage", (currentPage) => {

      if (this.description == null) {
        if (this.pageNumber == currentPage) {
          getIoSolutions()
            .then((response) => {
              this.ioSolutions = response.data.projects.length;
            })
            .catch((error) => {
              console.log(error);
            });
          getCompanyNumbers()
            .then((response) => {
              this.description = response.data.numbers.intro.pt;
              this.students = response.data.numbers.students.number;
              this.resources = response.data.numbers.resources;
              this.ioHelpers = response.data.numbers.ioHelpers;
              this.ioAgents = response.data.numbers.ioAgents;

              for (var institution in response.data.numbers.students
                .institutions) {
                this.institutions.push(
                  response.data.numbers.students.institutions[institution] +
                    " " +
                    "|" +
                    " "
                );
              }

              for (var tech in response.data.numbers.technologies) {
                this.technologies.push(
                  response.data.numbers.technologies[tech] + " " + "|" + " "
                );
              }

              for (var shareholder in response.data.numbers.technologies) {
                this.shareholders.push({
                  name:
                    response.data.numbers.shareholders[shareholder].name +
                    " " +
                    "|" +
                    " ",
                  percentage:
                    response.data.numbers.shareholders[shareholder].value + "%",
                });
              }

              this.technologies[this.technologies.length - 1] =
                response.data.numbers.technologies[
                  this.technologies.length - 1
                ];
              this.shareholders[this.shareholders.length - 1].name =
                response.data.numbers.shareholders[
                  this.shareholders.length - 1
                ].name;
              this.institutions[this.institutions.length - 1] =
                response.data.numbers.students.institutions[
                  this.institutions.length - 1
                ];
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
  },
};
</script>

<style>
body {
  font-family: Montserrat Bol;
}

.n {
  margin-left: 40%;
  font-size: 20px;
  position: absolute;
  margin-top: 32%;
}

.h-resources {
  background-color: #0b71b3;
  position: absolute;
  top: 95px;
  margin-left: 380px;
}

.hr {
  top: 110px;
  margin-left: 465px;
}

.tech-lang {
  background-color: #269736;
  position: absolute;
  top: 200px;
  margin-left: 450px;
}

.tch {
  top: 215px;
  margin-left: 535px;
}

.shareholders {
  background-color: #3aa2ad;
  position: absolute;
  top: 325px;
  margin-left: 470px;
}

.ac {
  top: 340px;
  margin-left: 555px;
}

.students {
  background-color: #b52b4d;
  position: absolute;
  top: 450px;
  margin-left: 440px;
}

.st {
  top: 465px;
  margin-left: 525px;
}

.io-solutions {
  background-color: #f9b233;
  position: absolute;
  top: 555px;
  margin-left: 350px;
}

.ioS {
  top: 595px;
  margin-left: 438px;
}

.io-helpers {
  background-color: #0b71b3;
  position: absolute;
  top: 605px;
  margin-left: 220px;
}

.ioH {
  top: 690px;
  margin-left: 223px;
}

.io-agents {
  background-color: #2b2b2b;
  position: absolute;
  top: 625px;
  margin-left: 80px;
}

.ioA {
  top: 710px;
  margin-left: 83px;
}

.format {
  font-size: 13px;
  color: black;
  position: absolute;
}

.round {
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  padding: 0;
  margin: 0;
  list-style: none;
}

.flex-item {
  margin: 20px 30px;
  padding: 5px;
  text-align: left;
}

.numbers-text {
  font-family: Montserrat Regular;
  font-size: 14px;
  text-align: left;
  padding-left: 80px;
  padding-right: 800px;
  display: inline-block;
  width: 229px;
  padding-top: 14px;
  line-height: 1.7;
  color: black;
}

.line {
  position: absolute;
  height: 0.2px;
  width: 220px;
  background-color: rgb(223, 223, 223);
  margin-top: 0px;
  margin-left: 80px;
}

@font-face {
  font-family: Montserrat Thi;
  src: url("../assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("../assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("../assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("../assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

@font-face {
  font-family: Montserrat Blac;
  src: url("../assets/Montserrat/Montserrat-Black.ttf");
  font-weight: black;
}

.numbers {
  background-image: linear-gradient(to left, #f9f7f7, #f9f7f7);
  background-size: 230%;
  background-position: 10% 5%;
  height: 1042px;
  width: 795px;
  justify-content: center;
  align-content: center;
  margin-right: auto;
  margin-left: auto;
  display: block;
  position: relative;
}

.team-numbers {
  font-family: Montserrat Blac, sans-serif;
  font-size: 19px;
  text-align: left;
  padding-left: 80px;
  padding-top: 100px;
  padding-right: 300px;
  color: #194980ce;
  text-transform: uppercase;
}
</style>