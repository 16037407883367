
<template>
  <div class="testimo">
    <div class="testi-template">
      <Header style="margin-top: 0px; color:black" />

      <div
        class="quotes"
        :key="testimony.author"
        v-for="testimony in testimonies"
      >
        <Testimony
          :testimony="testimony.desc"
          :author="testimony.author"
          :impar="testimony.impar"
          :class="testimony.impar ? 'leftText' : 'rightText'"
        />
      </div>
    </div>
  </div>
</template>


<script>
import Header from "../components/Header.vue";
import Testimony from "../components/Testimony.vue";

import { getStructureName } from "../api/companies";
import { getCompanyTestimonies } from "../api/companies";

export default {
  name: "testimo",
  props: ["pageId"],
  data() {
    return {
      testimonies: [],
      page: null,
    };
  },
  components: {
    Header,
    Testimony,
  },
  created() {
    getStructureName(this.pageId)
      .then((response) => {
        this.page = response.data.page;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    this.$root.$on("changepage", (currentPage) => {
      if (this.testimonies.length == 0) {
        if (this.page == currentPage) {
          getCompanyTestimonies()
            .then((response) => {
              let i = true;
              for (var testimony in response.data.testimonies.content) {
                this.testimonies.push({
                  desc: response.data.testimonies.content[testimony].description
                    .pt,
                  author: response.data.testimonies.content[testimony].name,
                  impar: i,
                });

                i = !i;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
  },
};
</script>

<style>
.leftText {
  margin-left: 100px;
  text-align: left;
}

.rightText {
  margin-right: 100px;
  text-align: right;
}

.quotes {
  margin-top: 60px;
}

.testi-template {
  height: 1042px;
  width: 795px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
}

.line {
  position: absolute;
  height: 0.2px;
  width: 220px;
  background-color: rgb(223, 223, 223);
  margin-top: 0px;
  margin-left: 80px;
}

@font-face {
  font-family: Montserrat Thi;
  src: url("../assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("../assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("../assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("../assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

@font-face {
  font-family: Montserrat Blac;
  src: url("../assets/Montserrat/Montserrat-Black.ttf");
  font-weight: black;
}

.testimo {
  background-image: linear-gradient(to left, #f9f7f7, #f9f7f7);
  background-size: 230%;
  background-position: 10% 5%;
  height: 1042px;
  width: 795px;
  justify-content: center;
  align-content: center;
  margin-right: auto;
  margin-left: auto;
  display: block;
  position: relative;
}

h3 {
  font-family: Montserrat Thi, sans-serif;
  font-size: 16px;
  text-align: left;
  padding-left: 80px;
  padding-top: 70px;
  padding-right: 300px;
  line-height: 2.3;
}

.vss {
  font-family: Montserrat Blac, sans-serif;
  font-size: 19px;
  text-align: left;
  padding-left: 80px;
  padding-top: 100px;
  padding-right: 300px;
  color: #194980ce;
}
</style>