<template>
  <div class="header">
    <input maxlength="50"
      class="search-input"
      :class="{ shown: clicked}"
      type="text"
      placeholder="Pesquisar"
      v-on:keyup.enter="actiInput()"
      v-model="inputvalue"
      :style="'color: ' + inputColor"
    />
    <div class="click-search" @click="search">
      <b-icon
        icon="magnify"
        size="is-small"
        class="icon custom-class-search"
      >
      </b-icon>
    </div>
    <div class="gohome" @click="goHome()"
      ><b-icon
        icon="home"
        size="is-small"
        class="icon custom-class-home"
        
      >
      </b-icon
    ></div>

    <div style="background-color: transparent; width: 20px; height: 10px"></div>
  </div>
</template>

<script>
export default {
  name: "exported-header",
  props:["inputColor"],
  data() {
    return {
      clicked: false,
      active: false,
      inputvalue: "",
    };
  },
  components: {},
  methods: {
    search() {
      this.clicked = !this.clicked;
    },
    actiInput() {
      this.clicked = !this.clicked;
      this.inputvalue = "";
    },
    goHome(){
      this.$root.$emit("goHome");
    },
  },
};
</script>


<style scoped>
.click-search {
  position: absolute;
  left: 45px;
  width: 25px;
  height: 25px;
  margin-right: 90px;
  margin-top: 1px;
  cursor: pointer;
}

.gohome{
  position: absolute;
  left: 20px;
  width: 25px;
  height: 25px;
  margin-right: 22px;
  margin-top: 1px;
  cursor: pointer;
}

.home-icon {
  fill: rgba(0, 0, 0, 0.938);
}

.header {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
}

.icon {
  position: absolute;
  right: 0;
}

.shown {
  display: inline-block;
}

.activate {
  display: block;
}

.display {
  display: block;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  font-family: Montserrat Regular;
  font-size: 12px;
  background-color: transparent;
  margin-top: 2px;
  position: absolute;
  left: 75px;
  width: 630px;
  display: none;
}


</style>