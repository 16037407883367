import { render, staticRenderFns } from "./IotechInfo.vue?vue&type=template&id=47ac2aae&"
import script from "./IotechInfo.vue?vue&type=script&lang=js&"
export * from "./IotechInfo.vue?vue&type=script&lang=js&"
import style0 from "./IotechInfo.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports