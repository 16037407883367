<template>
  <div class="IoCover" >
    <div class="iocover-template" :style="'background-color:' + maincolor">
      <Header style="margin-top: 0px; color: white" input-color="white"/>

      <h1
        class="title"
        style="
          text-align: left;
          padding-left: 80px;
          padding-top: 80px;
          font-size: 60px;
          font-family: Montserrat Regular, sans-serif;
          position: relative;
          text-transform: uppercase;
        "
      >
        {{ iosolName }}
      </h1>

      <div class="linha"></div>

      <h2 style="font-family: Montserrat Medi, sans-serif; padding-top: 40px">
        {{ slogan }}
      </h2>

      <h3
        style="
          position: relative;
          padding-bottom: 50px;
          padding-top: 20px;
          padding-left: 85px;
        "
      >
        {{ productDesc }}
      </h3>

      <img class="iconIO" :src="'data:image/jpeg;base64,' + logo" />

    </div>
  </div>
</template>


<script>
import Header from "../components/Header.vue";  

import { getStructureName } from "../api/companies";
import { getIoSolutionByName } from "../api/iosolutions";

export default {
  name: "ioCover",
  props: ["iosolName"],
  components: {
    Header,
  },
  data() {
    return {
      productDesc: null,
      slogan: "",
      backcolor: "",
      page: null,
      maincolor: null,
      logo: null
    };
  },
  methods: {},

  mounted() {
    this.$root.$on("changepage", (currentPage) => {
      if (this.productDesc == null) {
        if (this.page == currentPage) {
          getIoSolutionByName(this.iosolName)
            .then((response) => {
              this.productDesc =
                response.data.projects[0].info.description.short.pt;
              this.slogan =
                response.data.projects[0].info.description.slogan.pt;
                this.maincolor = response.data.projects[0].color[0];
                this.logo = response.data.projects[0].logos.white;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
  },
  created() {
    getStructureName(this.iosolName + "cover")
      .then((response) => {

        this.page = response.data.page;

      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
.iconIO {
  width: 33%;
  padding-left: 480px;
  margin-bottom: 50px;
}

.linha {
  height: 1px;
  width: 450px;
  background-color: white;
  margin-left: 85px;
}

@font-face {
  font-family: Montserrat Thi;
  src: url("../assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("../assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("../assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("../assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

.title {
  text-align: right;
  font-size: 65px;
  padding-right: 90px;
  padding-left: 285px;
  padding-top: 0px;
  margin: 0px 0px;
  font-family: Montserrat Regular, sans-serif;
  position: absolute;
}

.IoCover {
  background-image: url("../assets/rede.png");
  background-size: 230%;
  background-position: 10% 5%;
  background-repeat: no-repeat;
  height: 1042px;
  width: 795px;
  justify-content: center;
  align-content: center;
  margin-right: auto;
  margin-left: auto;
  display: block;
  position: relative;
}

.iocover-template {
  height: 1042px;
  width: 795px;
  overflow: hidden;
}

h3 {
  font-family: Montserrat Thi, sans-serif;
  font-size: 16px;
  text-align: left;
  padding-left: 90px;
  padding-top: 70px;
  line-height: 2.3;
}

h2 {
  font-family: Montserrat Regular, sans-serif;
  font-size: 16px;
  text-align: left;
  padding-left: 85px;
  padding-top: 10px;
  padding-right: 300px;
}

.data {
  font-family: Montserrat Regular, sans-serif;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
  padding-left: 20px;
  text-align: left;
}
</style>