<template>
  <div class="assets">
    <div class="assets-template">
      <Header style="margin-top: 0px; color:black;" />

      <h2 class="vss1">Ativos</h2>
      <div class="line"></div>

      <ul class="flex-container assets-container">
        <li class="flex-item" :key="asset.id" v-for="asset in assets">
          <IoAsset
            :asset-name="asset.name"
            :asset-logo="asset.logo"
          />
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
import Header from "../components/Header.vue";
import IoAsset from "../components/Asset.vue";

import { getCompanyAssets } from "../api/companies";

export default {
  name: "assets",
  props: ["pageNumber", "pageId"],
  components: {
    Header,
    IoAsset,
  },
  data() {
    return {
      assets: [],
    };
  },
  mounted() {
    this.$root.$on("changepage", (currentPage) => {
      if (this.assets.length == 0) {
        if (this.pageNumber == currentPage) {
          getCompanyAssets()
            .then((response) => {
              for (var asset in response.data.assets) {
                this.assets.push({
                  name: response.data.assets[asset].name,
                  logo: response.data.assets[asset].logo,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
  },
};
</script>

<style>
.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  padding: 0;
  margin: 0;
  list-style: none;
}

.assets-container {
  margin-left: 40px;
}

.assets-template {
  height: 1042px;
  width: 795px;
  overflow: hidden;
  display: block;
}

.flex-item {
  margin: 20px 30px;
  padding: 5px;
  text-align: left;
}

.roles {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  text-align: left;
  margin-left: 90px;
  line-height: 1.7;
  color: black;
  font-weight: bolder;
}

.in-english {
  color: #bcbcbc;
  display: inline-block;
  margin-left: 5px;
}
.assets-text {
  font-family: Montserrat Thi, sans-serif;
  font-size: 14px;
  text-align: left;
  padding-left: 80px;
  padding-top: 15px;
  padding-right: 80px;
  line-height: 1.7;
  color: black;
}

.line {
  position: absolute;
  height: 0.2px;
  width: 220px;
  background-color: rgb(223, 223, 223);
  margin-top: 0px;
  margin-left: 80px;
}

@font-face {
  font-family: Montserrat Thi;
  src: url("../assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("../assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("../assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("../assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

@font-face {
  font-family: Montserrat Blac;
  src: url("../assets/Montserrat/Montserrat-Black.ttf");
  font-weight: black;
}

.assets {
  background: #f9f7f7;
  height: 1042px;
  width: 795px;
  justify-content: center;
  align-content: center;
  margin-right: auto;
  margin-left: auto;
  display: block;
  position: relative;
  /*overflow-y:auto;
  overflow-x: auto;*/
}

h3 {
  font-family: Montserrat Thi, sans-serif;
  font-size: 16px;
  text-align: left;
  padding-left: 80px;
  padding-top: 70px;
  padding-right: 300px;
  line-height: 2.3;
}

.vss1 {
  font-family: Montserrat Blac, sans-serif;
  font-size: 19px;
  text-align: left;
  padding-left: 80px;
  padding-top: 70px;
  padding-right: 300px;
  color: #194980ce;
  text-transform: uppercase;
}
</style>