<template>
  <section style="margin-top: -20px">
    <div class="backg">
      <div class="area-template" :style="'background-color:'+ backgroundColor">
        <Header style="color: white" input-color="white"/>

        <h1
          class="title"
          style="
            text-align: left;
            padding-left: 80px;
            padding-top: 80px;
            font-size: 60px;
            font-family: Montserrat Regular, sans-serif;
            position: relative;
            text-transform: uppercase;
          "
        >
          {{ this.title }}
        </h1>

        <div class="linha"></div>

        <h3
          style="
            position: relative;
            padding-bottom: 50px;
            padding-top: 28px;
            padding-left: 85px;
          "
        >
          <!--  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in
          ligula feugiat, ornare nulla at, venenatis metus. Sed turpis dolor,
          cursus eu feugiat ac, fermentum in diam. -->
          {{ shortDescription }}
        </h3>

        <p
          v-for="(link, index) in this.links"
          :key="link.id"
          @click="change(linksroute[index])"
          class="link"
        >
          {{ link }}
        </p>
      </div>
    </div>
  </section>
</template>


<script>
import Header from "../components/Header.vue";
import { getCompanyAreaByName, getStructureName } from "../api/companies";
import { getIoSolutionsNames } from "../api/iosolutions";
import { getProjectByType } from "../api/externalProject";


export default {
  name: "exported-area",
  props: ["subArea", "pageNumber", "pageId", "area"],
  data() {
    return {
      shortDescription: null,
      title: null,
      links: [],
      linksroute: [],
      path: this.$route.query.path,
      page: null,
      backgroundColor: null,
    };
  },
  components: {
    Header,
  },
  watch: {},
  methods: {
    change(div) {
      this.$root.$emit("scrollto", div);
    },
  },
  mounted() {
    this.$root.$on("changepage", (currentPage) => {

      if (this.links.length == 0) {
        if (this.page == currentPage) {
          if (this.pageId == "IoTech") {
            this.links = ["Sobre", "Equipa", "Números", "IoSolutions"];
            this.linksroute = ["info", "team", "numbers", "IoSolutions"];
          } else if (this.pageId == "IoSolutions") {
            this.links = [];
            this.linksroute = [];
            getIoSolutionsNames()
              .then((response) => {
                for (var project in response.data.projects) {
                  this.links.push(response.data.projects[project]);
                  this.linksroute.push(response.data.projects[project] + "cover");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }else if(this.area != null){
            this.links = [];
            this.linksroute = [];
            getProjectByType(this.pageId)
            .then((response) => {
              console.log(response);
              for(var project in response.data[0].projects)
              this.links.push(response.data[0].projects[project].info.name);
              this.linksroute.push(response.data[0].projects[project].info.name);
            })
            .catch((error) => {
              console.log(error);
            });
          }
          getCompanyAreaByName(this.pageId)
            .then((response) => {
              this.shortDescription = response.data.areas[0].intro.pt;
              this.title = response.data.areas[0].name.pt;
              this.backgroundColor = response.data.areas[0].color;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
  },
  created() {
    this.page = this.pageNumber;
    if(this.pageNumber == null){
      getStructureName(this.pageId)
      .then((response) => {
        this.page = response.data.page;

      })
      .catch((error) => {
        console.log(error);
      });
    }
    
  },
  
};
</script>


<style lang="scss">
.backg {
  display: block;
  position: relative;
  background-image: url("../assets/rede.png");
  height: 1042px;
  width: 795px;
  
}

.link {
  cursor: pointer;
  font-size: 18px;
  margin-left: 85px;
}

button {
  appearance: none;
  outline: none;
  background-color: transparent;
  border: none;
  color: rgb(226, 226, 226);
  cursor: pointer;
  position: absolute;
  margin-top: -130px;
}

a {
  font-family: Montserrat Bol;
  font-size: 18px;
  text-align: left;
  padding-left: 85px;
  padding-top: 10px;
  padding-right: 300px;
  text-decoration: none;
  color: white;
}

.linha {
  height: 1px;
  width: 450px;
  background-color: white;
  margin-left: 85px;
  position: relative;
}

@font-face {
  font-family: Montserrat Thi;
  src: url("../assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("../assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("../assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("../assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

.title {
  text-align: right;
  font-size: 65px;
  padding-right: 90px;
  padding-left: 285px;
  padding-top: 0px;
  padding-bottom: 40px;
  margin: 0px 0px;
  font-family: Montserrat Regular, sans-serif;
  position: relative;
}

.area-template {
  height: 1042px;
  width: 795px;
}

h3 {
  font-family: Montserrat Thi, sans-serif;
  font-size: 16px;
  text-align: left;
  padding-left: 80px;
  padding-right: 300px;
  line-height: 2.3;
}

.data {
  font-family: Montserrat Regular, sans-serif;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
  padding-left: 20px;
  text-align: left;
}
</style>    