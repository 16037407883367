<template>
  <section>
    <div class="backC">
      <div class="backc-template">
        <div class="backContent">
          <img class="logBack" src="../assets/logWhite.png" />
          <h3 class="contacInfo">iotech.pt</h3>
          <h3 class="resumeInfo">
            IOTech - Innovation on Tecnology Famalicão Made INcubar - Polo
            Riopele Avenida da Riopele, n 946, 4770-405 Pousada de Saramagos
          </h3>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "backC",
  components: {},
  props: ["currentPage"]
};
</script>

<style scoped>
.logBack {
  width: 20%;
  height: 20%;
  padding-left: 80px;
  padding-top: 790px;
}

.backContent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.backc-template {
  height: 1042px;
  width: 795px;
  overflow: hidden;
}

.resumeInfo {
  position: relative;
  padding-bottom: 50px;
  padding-top: 790px;
  font-size: 13px;
  padding-right: 80px;

  flex: 2;
}

.contacInfo {
  position: relative;
  padding-bottom: 50px;
  padding-top: 790px;
  font-size: 13px;

  flex: 1;
}

@font-face {
  font-family: Montserrat Thi;
  src: url("../assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("../assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("../assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("../assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

.title {
  text-align: left;
  padding-left: 80px;
  padding-top: 80px;
  font-size: 70px;
  font-family: Montserrat Regular, sans-serif;
  position: relative;
  padding-bottom: 40px;
}

.backC {
  background-color: #012c49;
  background-size: 230%;
  background-position: 10% 5%;
  height: 1042px;
  width: 795px;
  justify-content: center;
  align-content: center;
  margin-right: auto;
  margin-left: auto;
  display: block;
  position: relative;
}

h3 {
  font-family: Montserrat Thi, sans-serif;
  font-size: 16px;
  text-align: left;
  padding-left: 60px;
  padding-top: 70px;
  padding-right: 5px;
  line-height: 2.3;
}

h2 {
  font-family: Montserrat Regular, sans-serif;
  font-size: 16px;
  text-align: left;
  padding-left: 80px;
  padding-top: 10px;
  padding-right: 300px;
}

.data {
  font-family: Montserrat Regular, sans-serif;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
  padding-left: 20px;
  text-align: left;
}
</style>    