<template>
  <div id="app">
    <div class="app-container" id="container">
      <div @click="goBack()" class="goleft12">
        <router-link
          v-if="routes.length > 0"
          to="/"
          style="color: rgb(109, 109, 109)"
          ><b-icon icon="chevron-left" size="is-medium"> </b-icon
        ></router-link>
      </div>

      <div @click="goForward()">
        <router-link
          v-if="routes.length > 0"
          class="rightarrow11"
          style="color: rgb(109, 109, 109)"
          to="/"
          ><b-icon icon="chevron-right" size="is-medium"> </b-icon
        ></router-link>
      </div>

      <Cover id="cover" page-number="1" page-id="cover" />
      <Area id="IoTech" page-number="2" page-id="IoTech" />
      <Info id="info" page-number="3" page-id="info" />
      <Team id="team" page-number="4" page-id="team" />
      <Numbers id="numbers" page-number="5" page-id="numbers" />
      <Assets id="assets" page-number="6" page-id="assets" />
      <Area id="IoSolutions" page-number="7" page-id="IoSolutions" />
      <div
        v-for="iosolution in iosolutions"
        :key="iosolution.id"
        class="container2"
      >
        <IoSCover
          :page-number="null"
          :iosol-name="iosolution"
          :id="iosolution + 'cover'"
        />
        <IoSolution
          :iosol-name="iosolution"
          :id="iosolution"
        />
      </div>
      <div v-for="area in projectsareas" :key="area.id" class="container2">
        <Area :page-number="null" :page-id="area.name" :id="area.name" area="yes"/>
        <ExternalPro
          v-for="project in area.projects"
          :key="project.id"
          :externalPro="project"
          :area-type="area.name"
        />
      </div>

      <Area
        v-show="testimonies"
        :page-number="null"
        id="Testimonies"
        page-id="Testimonies"
      />
      <Testimonies
        v-show="testimonies"
        id="testimonies"
        page-id="testimonies"
      />
      <Partners id="partners" page-id="partners" />
      <BackCover
        :page-number="this.routes.length"
        id="back cover"
        page-id="back cover"
      />

      <Footer
        style="color: #fff"
        :number-page="pageNumber"
        :total-number="this.routes.length"
      />
    </div>
  </div>
</template>

<script>
import Cover from "./views/Cover.vue";
import Area from "./views/Area.vue";
import Info from "./views/IotechInfo.vue";
import Team from "./views/IotechTeam.vue";
import Numbers from "./views/IotechNumbers.vue";
import Assets from "./views/IotechAssets.vue";
import IoSCover from "./views/IoSolutionCover.vue";
import IoSolution from "./views/IoSolution.vue";
import ExternalPro from "./views/ExternalProject.vue";
import Testimonies from "./views/IotechTestimony.vue";
import Partners from "./views/Partners.vue";
import BackCover from "./views/BackCover.vue";
import Footer from "@/components/Footer.vue";

import {
  getStructure,
  getStructureName,
  getProjectsAreas,
} from "@/api/companies";
import { getIoSolutionsNames } from "./api/iosolutions";

export default {
  name: "App",
  data() {
    return {
      subareas: [],
      number: 7,
      path: this.$route.query.path,
      pageNumber: 1,
      iosolutions: [],
      projectsareas: [],
      routes: [],
      testimonies: false,
      ecommercepagenumber: "",
      induspagenumber: "",
    };
  },
  components: {
    Cover,
    Area,
    Info,
    Team,
    Numbers,
    Assets,
    IoSCover,
    IoSolution,
    ExternalPro,
    Testimonies,
    Partners,
    BackCover,
    Footer,
  },
  watch: {
    path(value) {
      if (value == "info") {
        document.getElementById("info").scrollIntoView();
      }
    },
  },

  methods: {
    goHome() {
      document.getElementById("cover").scrollIntoView();
      this.pageNumber = 1;
    },
    goBack() {
      if (this.pageNumber > 1) {
        this.pageNumber--;
        this.$root.$emit("slideleft");
      }
    },
    goForward() {
      if (this.pageNumber < this.routes.length) {
        this.pageNumber++;
        this.$root.$emit("slideright");
      }
    },
  },
  mounted() {
    this.$root.$on("scrollto", (div) => {
      var goto = div;

      if (document.getElementById(goto) != null) {
        //redefinir os números das páginas
        getStructureName(goto)
          .then((response) => {
            this.pageNumber = response.data.page;
            this.$root.$emit("changepage", this.pageNumber);

            document.getElementById(goto).scrollIntoView();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    if (this.path == "numbers") {
      document.getElementById("numbers").scrollIntoView();
    }
    this.$root.$on("slideright", () => {
      this.$root.$emit("changepage", this.pageNumber);
      document.getElementById("container").scrollLeft += 795;
    });
    this.$root.$on("slideleft", () => {
      this.$root.$emit("changepage", this.pageNumber);
      document.getElementById("container").scrollLeft -= 795;
    });
    this.$root.$on("goHome", () => {
      this.goHome();
    });
  },
  created() {
    getStructure()
      .then((response) => {
        for (var route in response.data.projects) {
          this.routes.push({
            name: response.data.projects[route].name,
            page: response.data.projects[route].page,
          });
        }

        for (var routee in this.routes) {
          if (this.routes[routee].name == "Testimonies") {
            this.testimonies = true;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getIoSolutionsNames()
      .then((response) => {
        for (var iosolution in response.data.projects) {
          this.iosolutions.push(response.data.projects[iosolution]);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getProjectsAreas()
      .then((response) => {

        this.projectsareas = response.data.areas
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>


<style lang="scss">
.app-container {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  width: 795px;
  scroll-behavior: smooth;
  transition: 0.5s;
  margin-top: 20px;
  overflow: hidden;
}

.container2 {
  display: flex;
  flex-direction: row;
}

html {
  margin: 0;
  padding: 0;
  background-color: rgba(226, 239, 248, 0.884);
  padding: 0;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.goleft12 {
  position: absolute;
  color: rgb(109, 109, 109);
  text-align: left;
  left: 0;
  align-content: left;
  top: 50%;
  margin-left: -85px;
}

.rightarrow11 {
  position: absolute;
  text-align: right;
  right: 0;
  align-content: right;
  padding-right: 2px;
  color: rgb(109, 109, 109);
  top: 50%;
}

::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: rgb(231, 231, 231); /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(184, 184, 184); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Montserrat Thi;
  src: url("assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}
</style>