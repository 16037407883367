<template>
  <div class="testiIO">
    <span class="testi-text" id="quote">
      {{ testimony }} </span>
    <span class="author">{{ author }}</span>
  </div>
</template>

<script>
export default {
  name: "testiIO",
  props: ["testimony", "author", "impar"],
};
</script>

<style scoped>

.testiIO {
 width: 600px;
}
.author {
  font-family: Montserrat Bol, sans-serif;
  font-size: 12px;
  display: block;
  margin-top: 20px;
  color: #000000;
}

.testi-text {
  font-family: Montserrat Regular, sans-serif;
  font-size: 12px;
  line-height: 1.7;
  color: black;
}

#quote {
  quotes: "“" "”" "‘" "’";
}

#quote::before {
  content: open-quote;
  color: rgb(15, 45, 76);
  font-weight: bold;
  font-size: 40px;
}

#quote::after {
  content: close-quote;
  color: rgb(15, 45, 76);
  font-weight: bold;
  font-size: 40px;
}
</style>