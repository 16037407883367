import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Buefy from 'buefy'
import VueSplide from '@splidejs/vue-splide'

import VueRouter from 'vue-router';
import router from './router'
Vue.use(VueRouter);

/* const router = new VueRouter({mode: 'history', routes});
 */

Vue.use(Buefy);

Vue.use( VueSplide );

Vue.config.productionTip = false;



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
