<template>
  <div class="partnersIO">
    <Header style="margin-top: 0px; color: black" />
    <div class="partn-template">
      <div v-for="object in this.types" :key="object.id">
        <div v-if="object.partnerships.length > 0">
          <h2 class="vss vsss">{{ object.type }}</h2>
          <div class="line"></div>

          <ul class="flex-container partn-container">
            <li
              class="flex-item"
              v-for="partner in object.partnerships"
              :key="partner.id"
            >
              <img :src="'data:image/jpeg;base64,' + partner.logo" />
              <span class="assets-tex">{{ partner.name }}</span>
            </li>
          </ul>
        </div>
      </div>

      <h2 class="vss" v-if="networks.length > 0">Redes</h2>
      <div class="line" v-if="networks.length"></div>

      <ul class="flex-container partn-container">
        <li
          class="flex-item"
          v-for="network in this.networks"
          :key="network.id"
        >
          <img :src="'data:image/jpeg;base64,' + network.logo" />
          <span class="assets-tex">{{ network.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
import Header from "../components/Header.vue";

import {
  getPartnershipsByType,
  getStructureName,
  getCompanyNetworks,
  getPartnershipTypes,
} from "../api/companies";

export default {
  name: "partnersIO",
  props: ["pageId"],
  components: {
    Header,
  },
  data() {
    return {
      page: null,
      networks: [],
      types: [],
    };
  },
  created() {
    getStructureName(this.pageId)
      .then((response) => {
        this.page = response.data.page;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    this.$root.$on("changepage", (currentPage) => {
      if (this.types.length == 0) {
        if (this.page == currentPage) {
          getPartnershipTypes()
            .then((response) => {
              for (var type in response.data.types) {
                this.types.push({
                  type: response.data.types[type],
                  partnerships: [],
                });

                let searchtype = response.data.types[type];

                getPartnershipsByType(searchtype)
                  .then((response) => {
                    for (var partner in response.data[0].partnerships) {
                      for (var object in this.types) {
                        if (this.types[object].type == searchtype) {
                          this.types[object].partnerships.push({
                            name: response.data[0].partnerships[partner].name,
                            logo: response.data[0].partnerships[partner].logo,
                          });
                        }
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });

          getCompanyNetworks()
            .then((response) => {
              for (var network in response.data.networks) {
                this.networks.push({
                  name: response.data.networks[network].name,
                  logo: response.data.networks[network].logo,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  padding: 0;
  margin: 0;
  list-style: none;
}

img {
  width: 100px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
}

.partn-container {
  margin-left: 40px;
}

.flex-item {
  margin: 20px 30px;
  padding: 5px;
  text-align: left;
}

.in-english {
  color: #bcbcbc;
  display: inline-block;
  margin-left: 5px;
}
.partn-template {
  height: 900px;
  margin-top: 50px;
  width: 795px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
}
.assets-tex {
  font-family: Montserrat Regular, sans-serif;
  font-size: 14px;
  text-align: left;
  padding-left: 5px;
  padding-right: 80px;
  line-height: 1.7;
  color: black;
}

.line {
  position: relative;
  height: 0.2px;
  width: 220px;
  background-color: rgb(223, 223, 223);
  margin-top: 0px;
  margin-left: 80px;
}

@font-face {
  font-family: Montserrat Thi;
  src: url("../assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("../assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("../assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("../assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

@font-face {
  font-family: Montserrat Blac;
  src: url("../assets/Montserrat/Montserrat-Black.ttf");
  font-weight: black;
}

.partnersIO {
  background: #f9f7f7;
  height: 1042px;
  width: 795px;
  justify-content: center;
  align-content: center;
  margin-right: auto;
  margin-left: auto;
  display: block;
  position: relative;
}

h3 {
  font-family: Montserrat Thi, sans-serif;
  font-size: 16px;
  text-align: left;
  padding-left: 80px;
  padding-top: 70px;
  padding-right: 300px;
  line-height: 2.3;
}

.vss {
  font-family: Montserrat Blac, sans-serif;
  font-size: 19px;
  text-align: left;
  padding-left: 80px;
  padding-top: 70px;
  padding-right: 300px;
  color: #194980ce;
  text-transform: uppercase;
}

.vsss {
  padding-top: 10px;
}
</style>