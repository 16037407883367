<template>
  <section>
    <div class="ioasset"><img :src="'data:image/jpeg;base64,' + assetLogo" /></div>
    <span class="name">{{assetName}}</span>
  </section>
</template>

<script>
export default {
  name: "ioasset",
  props: ["assetName", "assetLogo"],
};
</script>

<style scoped>
.name {
  color: black;
  font-family: Montserrat Regular, sans-serif;
  text-transform: capitalize;
}
.ioasset {
  width: 100px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>