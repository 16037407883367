<template>
  <section style="margin-top: -20px">
    <div class="team-info">
      <Header style="color:black;"/>

      <img
        class="data"
        src="../assets/logoIOT.png"
        style="padding-left: 80px; padding-bottom: 870px; width: 40%"
      />

      <span class="longtext" id="description" style="margin-top: 28px">
        {{ longDescription }}
      </span>

      <h2 class="mss">MISSÃO</h2>
      <div class="line"></div>

      <span class="tex" id="iomission"> {{ longDescription }} </span>

      <h2 class="mss">VISÃO</h2>
      <div class="line"></div>

      <span class="tex" id="vision"> {{ visionDescription }} </span>

      <h2 class="mss">CONTACTOS</h2>
      <div class="line"></div>

      <table class="contacts-table" id="table">
        <tr :key="contact.id" v-for="contact in contacts">
          <td class="contact-icon"><img :src="'data:image/jpeg;base64,' + contact.icon" /></td>
          <td class="contact">{{ contact.name }}</td>
        </tr>
      </table>
    </div>
  </section>
</template>


<script>
import Header from "../components/Header.vue";
import { getCompanyDescription } from "../api/companies";
import { getCompanyContacts } from "../api/companies";
export default {
  name: "exported-info",
  props: ["pageNumber"],
  data() {
    return {
      longDescription: null,
      missionDescription: null,
      visionDescription: null,
      contacts: [],
    };
  },
  components: {
    Header,
  },
  methods: {},
  mounted() {
    this.$root.$on("changepage", (currentPage) => {

      if (this.longDescription == null) {
        if (this.pageNumber == currentPage) {
          getCompanyDescription()
            .then((response) => {
              this.longDescription = response.data.descriptions.long.pt;
              this.missionDescription = response.data.descriptions.mission.pt;
              this.visionDescription = response.data.descriptions.vision.pt;
            })
            .catch((error) => {
              console.log(error);
            });

          getCompanyContacts()
            .then((response) => {
              for (let i = 0; i < response.data.contacts.length; i++) {
                this.contacts.push({
                  name: response.data.contacts[i].name,
                  icon: response.data.contacts[i].icons,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
  },
};
</script>

<style>
.contacts-table td {
  padding-right: 10px;
}

.contacts-table {
  font-family: Montserrat Thi, sans-serif;
  padding-left: 77px;
  font-size: 14px;
  line-height: 1.7;
  color: black;
  font-weight: 600;
  padding-top: 10px;
  height: 80px;
}
.longtext {
  font-family: Montserrat Regular;
  font-size: 14px;
  text-align: left;
  padding-left: 80px;
  padding-top: 158px;
  padding-right: 80px;
  line-height: 1.7;
  color: black;
  display: inline-block;
}

.tex {
  font-family: Montserrat Regular;
  font-size: 14px;
  text-align: left;
  padding-left: 80px;
  display: inline-block;
  padding-top: 14px;
  line-height: 1.7;
  color: black;
  width: 350px;
  line-height: 1.6;
}

.line {
  position: absolute;
  height: 0.2px;
  width: 220px;
  background-color: rgb(223, 223, 223);
  margin-top: 0px;
  margin-left: 80px;
}

@font-face {
  font-family: Montserrat Thi;
  src: url("../assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("../assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("../assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("../assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

@font-face {
  font-family: Montserrat Blac;
  src: url("../assets/Montserrat/Montserrat-Black.ttf");
  font-weight: black;
}

.title {
  text-align: right;
  font-size: 65px;
  padding-right: 90px;
  padding-left: 285px;
  padding-top: 0px;
  margin: 0px 0px;
  font-family: Montserrat Regular, sans-serif;
  position: absolute;
}

.team-info {
  background-image: linear-gradient(to left, #f9f7f7, #f9f7f7);
  background-size: 230%;
  background-position: 10% 5%;
  height: 1042px;
  width: 795px;
  justify-content: center;
  align-content: center;
  margin-right: auto;
  margin-left: auto;
  display: block;
  position: relative;
}

h3 {
  font-family: Montserrat Thi, sans-serif;
  font-size: 16px;
  text-align: left;
  padding-left: 80px;
  padding-top: 70px;
  padding-right: 300px;
  line-height: 2.3;
}

.mss {
  font-family: Montserrat Blac, sans-serif;
  font-size: 19px;
  text-align: left;
  padding-left: 80px;
  padding-top: 14px;
  padding-right: 300px;

  color: #194980ce;
}

.data {
  font-family: Montserrat Regular, sans-serif;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
  padding-left: 20px;
  text-align: left;
}
</style>