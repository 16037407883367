<template>
  <div id="cover" class="cover">
    <div class="cover-template">
    <h3 class="h3">Innovation On Technology</h3>
    <div class="parent">
      <p class="portfolio">PORT</p>
      <p class="portfolio blue">FOLIO</p>
    </div>
    <div class="parent2">
      <p class="by-text">By</p>
      <img src="../assets/logoIOT.png" class="iologo" />
    </div>
    <p class="description">{{ description }}</p>

    <table>
      <tr>
        <td>
          <div class="area one" v-if="themes.length!=0" @click="change(themes[0].enname)">{{ themes[0].ptname }}</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="area two" v-if="themes.length!=0" @click="change(themes[1].enname)">{{ themes[1].ptname }}</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="area three" v-if="themes.length!=0" @click="change(themes[2].enname)">{{ themes[2].ptname }}</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="area four" v-if="themes.length!=0" @click="change(themes[3].enname)">{{ themes[3].ptname }}</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="area five" v-if="themes.length!=0" @click="change(themes[4].enname)">{{ themes[4].ptname }}</div>
        </td>
      </tr>
    </table>

    <div class="date">julho 2021</div>
    <div class="lang">PT | EN</div>

    </div>
  </div>
</template>

<script>
import { getCompanyDescription } from "../api/companies";
import { getCoverAreas } from "../api/companies";

export default {
  name: "capa",
  data() {
    return {
      description: "",
      themes: [],
    };
  },
  methods: {
    change(div) {
      this.$root.$emit("scrollto", div);
    },
  },
  created() {
    getCompanyDescription()
      .then((response) => {
        this.description = response.data.descriptions.intro.pt;
      })
      .catch((error) => {
        console.log(error);
      });

    getCoverAreas()
      .then((response) => {
        for (var theme in response.data[0].areas) {
          this.themes.push({ptname: response.data[0].areas[theme].name.pt, 
          enname: response.data[0].areas[theme].name.en});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
@font-face {
  font-family: Montserrat Thi;
  src: url("../assets/Montserrat/Montserrat-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Regular;
  src: url("../assets/Montserrat/Montserrat-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Montserrat Bol;
  src: url("../assets/Montserrat/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Montserrat Medi;
  src: url("../assets/Montserrat/Montserrat-Medium.ttf");
  font-weight: medium;
}

.cover {
  display: block;
  position: relative;
  width: 795px;
  height: 1042px;
  background-color: white;
  background-image: linear-gradient(
      90deg,
      rgba(1, 40, 67, 0.938) 61%,
      rgba(240, 240, 240, 0.901) 49%
    ),
    url("../assets/pc.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.cover-template {
  height: 1042px;
  width: 795px;
  overflow: hidden;
}

.h3 {
  font-family: Montserrat Thi, sans-serif;
  font-size: 29px;
  padding-top: 250px;
  position: relative;
  padding-left: 100px;
}

.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 163px;
}

.portfolio {
  display: inline-block;
  font-family: Montserrat Medi, sans-serif;
  font-size: 60px;
  position: relative;
  margin-top: -27px;
  margin-left: 25px;
}

.blue {
  color: #09598e;
}

.parent2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 409px;
}

.by-text {
  display: inline-block;
  font-family: Montserrat Regular, sans-serif;
  font-size: 29px;
  position: relative;
  color: #09598e;
  text-align: right;
  margin-top: -50px;
  margin-right: 20px;
}

.iologo {
  display: inline-block;
  width: 150px;
  margin-top: -80px;
}

.description {
  font-family: Montserrat Regular, sans-serif;
  font-size: 16px;
  text-align: left;
  line-height: 1.7;
  width: 320px;
  padding-left: 103px;
  padding-top: 80px;
}

.area {
  font-family: Montserrat Medi, sans-serif;
  padding-left: 13px;
  padding-right: 13px;
  height: 31px;
  float: right;
  padding-top: 8px;
  font-size: 19px;
  text-align: center;
}

.one {
  background-color: #0b71b3;
  margin-bottom: 10px;
}

.two {
  background-color: #09a144;
  margin-bottom: 10px;
}

.three {
  background-color: #04a8c9;
  margin-bottom: 10px;
}

.four {
  background-color: #b52b4d;
  margin-bottom: 10px;
}

.five {
  background-color: #e5a437;
  margin-bottom: 10px;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
}

table {
  position: absolute;
  right: 0;
  top: 620px;
  margin-right: -2px;
}

.date {
  font-family: Montserrat Regular, sans-serif;
  font-size: 16px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  padding-bottom: 16px;
  padding-left: 20px;
  text-align: left;
}

.lang {
  font-family: Montserrat Regular, sans-serif;
  position: absolute;
  font-size: 16px;
  left: 0;
  bottom: 0;
  width: 97.7%;
  padding-bottom: 16px;
  text-align: right;
}
</style>