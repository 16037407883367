import {
    get,
    post,
    put,
    del,
}from '../utils/http';

export function getCompany(){
    return get('/companies/');
}

export function postCompany(body){
    return post('/companies/', body);
}

export function getStructure(){
    return get('/companies/projects');
}

export function getStructureNumber(number){
    return get('/companies/projects/numbers/' + number)
}

export function getStructureName(sname){
    return get('/companies/projects/names/' + sname)
}

export function getCompanyDescription(){
    return get('/companies/descriptions');
}

export function putCompanyDescription(){
    return put('/companies/descriptions/');
}

export function getCompanyContacts(){
    return get('/companies/contacts');
}

export function putCompanyContacts(){
    return put('/companies/contacts');
}

export function getProjectsAreas(){
    return get('/companies/areas/projects');
}

export function getCompanyAreas(){
    return get('/companies/areas');
}

export function getCompanyAreaByName(name){
    return get('/companies/areas/' + name);
}

export function getCompanyAreaById(areaId){
    return get('/companies/areas/:' + areaId);
}

export function getAreaColor(name){
    return get('/companies/areas/' + name + '/colors');
}

export function putCompanyAreas(){
    return put('/companies/areas');
}

export function getCoverAreas(){
    return get('/companies/areas/orders');
}

export function getCompanyTestimonies(){
    return get('/companies/testimonies');
}

export function putCompanyTestimonies(){
    return put('/companies/testimonies');
}

export function getCompanyTeam(){
    return get('/companies/team');
}

export function putCompanyMember(){
    return put('/companies/team');
}

export function delCompanyMember(memberId){
    return del('/companies/team/:' + memberId);
}

export function getCompanyNumbers(){
    return get('/companies/numbers');
}

export function putCompanyNumber(){
    return put('/companies/numbers');
}

export function getCompanyAssets(){
    return get('/companies/assets');
}

export function putCompanyAsset(){
    return put('/companies/assets');
}

export function delCompanyAssets(assetId){
    return del('/companies/assets/:' + assetId);
}

export function getCompanyNetworks(){
    return get('/companies/networks');
}

export function putCompanyNetwork(){
    return put('/companies/networks');
}

export function delCompanyNetwork (networkId){
    return del('/companies/networks/:' + networkId);
}

export function getCompanyPartnerships(){
    return get('/companies/partnerships');
}

export function getPartnershipsByType(type){
    return get('/companies/partnerships/' + type);
}

export function getPartnershipTypes(){
    return get('/companies/partnerships/types');
}

